import { post } from "@/request/http";

export default {
    projectAdd(param, msg) { //add
        return post("/device/project/add", param, msg)
    },
    projectDel(param, msg) { //delete
        return post("/device/project/delete", param, msg)
    },
    projectUpdate(param, msg) { //update
        return post("/device/project/update", param, msg)
    },
    projectQueryPage(param, msg) { //queryPage
        // return post("/device/project/queryPage", param, msg)
        return post("/device/project/query/page/v2", param, msg)
    },
    projectQueryOptions2(param, msg) { //queryPage
        return post("/device/project/query/option", param, msg)
    },
    projectQueryOptions(param, msg) { //queryPage
        return post("/device/monitor/query-project", param, msg)
    },
    projectQueryById(param, msg) { //queryById
        return post("/device/project/queryById", param, msg)
    },
    projectUpload(param, msg) { //upload
        return post("/device/project/upload-project-info", param, msg)
    },
    projectDownload(param, msg) { //download
        return post("/device/project/download-project-infos", param, msg)
    },
    //监测点信息
    monitorInfoAdd(param, msg) { //新增监测点信息
        return post("/device/monitor/add", param, msg)
    },
    monitorInfoUpdate(param, msg) { //修改监测点信息
        return post("/device/monitor/update", param, msg)
    },
    monitorInfoDelete(param, msg) { //删除监测点信息
        return post("/device/monitor/delete", param, msg)
    },
    monitorInfoQueryById(param, msg) { //通过ID查询监测点信息详情
        return post("/device/monitor/query/id", param, msg)
    },
    monitorInfoQuery(param, msg) { //条件查询监测点信息
        return post("/device/monitor/query", param, msg)
    },
    monitorInfoQuerypage(param, msg) { //分页条件查询监测点信息
        return post("/device/monitor/query/page", param, msg)
    },
    simpleMonitorInfoQuery(param, msg) { //分页查询监测点简化版本
        return post("/device/monitor/query/simple", param, msg)
    },
    queryMonitorXcxQuery(param, msg) { //支持监测点编号、监测点名称、监测点项目名称、行政区划作为条件进行搜索
        return post("/device/monitor/query/xcx", param, msg)
    },
    queryMonitorListV2(param, msg) { //支持监测点编号、监测点名称、监测点项目名称、行政区划作为条件进行搜索
        return post("/device/monitor/query/v2", param, msg)
    },
    monitorInfoSearch(param, msg) { //模糊搜索
        return post("/device/monitor/search", param, msg)
    },
    bindMonitorDevice(param, msg) { //监控点绑定设备
        return post("/device/device/bindMonitorDevice", param, msg)
    },
    setMonitorDevice(param, msg) { //监测点绑定设备  监测详情
        return post("/device/device/set-monitor-data-device-show", param, msg)
    },
    getMonitorDevice(param, msg) { //监测点已绑定设备  监测详情
        return post("/device/device/query-monitor-data-show-device-list", param, msg)
    },
    queryMonitorDevice(param, msg) { //分页查询指定监测点下普通设备
        return post("/device/device/query-monitor-data-all-device-list", param, msg)
    },
    
    unbindMonitorDevice(param, msg) { //监控点解绑单个设备
        return post("/device/device/unbindMonitorDevice", param, msg)
    },
    deviceMonitorBatch(param, msg) { //批量删除
        return post("/device/monitor/delete/batch", param, msg)
    },
    deviceProjectBatch(param, msg) { //批量删除
        return post("/device/project/delete-batch", param, msg)
    },
    bindOrgMonitor(param, msg) { //批量删除
        return post("/device/monitor/bind-org", param, msg)
    },
    queryMonitorById(param, msg) { //忽略
        return post("/device/monitor/query/id", param, msg)
    },
    updateProductManufacturer(param, msg) { //修改合作厂商
        return post("/device/product/updateProductManufacturer", param, msg)
    },
    queryYldataPage(param, msg) { //分页查询所有雨量设备的数据
        return post("/device/device/query/yl-dates/page", param, msg)
    },
    queryYldataPageAll(param, msg) { //查询所有雨量设备的数据
        return post("/device/device/yl-dates/all", param, msg)
    },
    queryYldataById(param, msg) { //更新指定ID雨量设备数据
        return post("/device/device/sync/yl-dates", param, msg)
    },
    updateAllYlData(param, msg) { //更新所有雨量设备数据
        return post("/device/device/sync/yl-dates/all", param, msg)
    },
    downloadYlData(param, msg) { //更新所有雨量设备数据
        return post("/device/device/download/yl-dates", param, msg)
    },
    queryFunctionById(param, msg) { //查询功能入参配置列表
        return post("/device/function/input/config/query/list/function-id", param, msg)
    },
    queryProductFunctionListPageV2(param, msg) { //查询功能入参配置列表
        // return post("/device/product/queryProductFunctionListPage", param, msg)
        return post("/device/product/queryProductFunctionListPage/v2", param, msg)
    },
    functionCall(param, msg) { //下发
        return post("/device/device/function/call", param, msg)
    },
    functionLog(param, msg) { //指令调用日志分页查询
        return post("/device/device/function/log/query/page", param, msg)
    },
    functionInputAlias(param, msg) { //设置功能入参别名
        // id  alias
        return post("/device/function/input/config/edit/alias", param, msg)
    },
    functionAlias(param, msg) { //设置功能别名
        // id  alias
        return post("/device/product/set/function/alias", param, msg)
    },
    relationDevice(param, msg) { //设置功能别名
        return post("/device/device-monitor-relation/add", param, msg)
    },
    propertyGroupQueryData(param, msg) { //设置功能别名
        return post("/device/monitor/query/by-property-group/data", param, msg)
    },
    monitorWarningCount(param, msg) { //监测点数量统计
        return post("/device/monitor/data/id", param, msg)
    },
    monitorWeather(param, msg) { //气候查询
        return post("/device/monitor/weather", param, msg)
    },
    setMaxWarningStatus(param, msg){//置为最高预警
        return post("/device/event/monitor-warning/execute-max-warning-scene", param, msg)
    },
}