<template>
  <div class="clear">
    <div class="head-title w-100">
      <div class="flex-row w-100">
        <div class="flex-wrap logo">
          <!-- 使用地灾作为默认logo -->
          <!-- <img :src="$store.state.systemConfig.systemLogoFileUrl ? $FileBaseURL + $store.state.systemConfig.systemLogoFileUrl : require('@/assets/uiBack/login-title-blue.png')" alt="" srcset="" />-->
          <!-- 使用星浩科作为默认logo -->
          <img :src="$store.state.systemConfig.systemLogoFileUrl ? $FileBaseURL + $store.state.systemConfig.systemLogoFileUrl : require('@/assets/web-config/logo.png')" alt="" srcset="" />
          <!-- <span class="iconfont icon-shebeiguanli"></span> -->
          <el-divider direction="vertical" border-style="solid"/>
<!--          <span class="sub-title">{{ $store.state.systemConfig.organizationName || "自然资源部丘陵山地地质灾害重点实验室" }}</span>-->
          <span class="sub-title">{{ userInfo.orgName || "自然资源部丘陵山地地质灾害重点实验室" }}</span>
          <div class="logo-title">
            <!-- <span>北京市地质工程勘察院</span>
            <span>预警信息管理系统</span> -->
          </div>
        </div>
        <div class="flex-row right-wrap">
          <span class="sub-title"></span>
          <div class="header-right flex-row">
            <span class="menu" :class="{ 'active': active == 'other' }" @click="tagRoute('other')">
              <img :src="require(`../../assets/uiBack/btn1.png`)" style="transform: translateY(1);" />后台管理</span>
            <span class="menu" :class="{ 'active': active == 'map' }" @click="tagRoute('map')">
              <img :src="require(`../../assets/uiBack/btn2.png`)" style="transform: translateY(1);" />地图</span>
            <span v-if=" userInfo.id==='1' || userInfo.id==='2' " class="menu" :class="{ 'active': active == 'cockpit' }" @click="tagRoute('cockpit')"><img
                :src="require(`../../assets/uiBack/btn3.png`)" />数据大屏</span>
            <el-dropdown trigger="click" style="margin-left: 15px;" @command="handleCommand">
              <div class="flex-row">
                <el-popover placement="right" class="popovers" trigger="hover">
                  <el-form :model="userInfo" ref="formName" label-position="right" label-width="100px">
                    <el-form-item label="用户编号：">
                      <span class="userInfo-span">{{ userInfo.userCode }}</span>
                    </el-form-item>
                    <el-form-item label="用户名：">
                      <span class="userInfo-span">{{ userInfo.username }}</span>
                    </el-form-item>
                    <el-form-item label="姓名：">
                      <span class="userInfo-span">{{ userInfo.name }}</span>
                    </el-form-item>
                    <el-form-item label="所属机构：">
                      <span class="userInfo-span">{{ userInfo.orgName }}</span>
                    </el-form-item>
                  </el-form>
                  <el-avatar slot="reference" :src="require(`../../assets/uiBack/avatar.png`)"></el-avatar>
                </el-popover>
                <span> {{ $store.state.userInfo ? JSON.parse($store.state.userInfo).username : '' }}</span>
                <i class="el-icon-caret-bottom"></i>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown trigger="click" @command="handleCommand">
                  <el-dropdown-item icon="el-icon-sort el-icon--right" command="switchRole">角色切换</el-dropdown-item>
                  <el-dropdown-menu slot="dropdown" class="t-l">
                    <el-dropdown-item v-for="(item, i) in JSON.parse($store.state.userInfo).bindRoleInfos" :key="i"
                      :command="item.id">{{ item.name }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown-item icon="el-icon-edit-outline el-icon--right" command="updatePsw">修改密码</el-dropdown-item>
                <el-dropdown-item icon="el-icon-switch-button el-icon--right" command="loginOut">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setRouterByMenu from '@/main'
export default {
  name: "Header",
  data() {
    return {
      active: 'other',
      userInfo: this.$store.state.userInfo ? JSON.parse(this.$store.state.userInfo) : {},
    };
  },
  watch: {
    "$route": {
      handler(to, from) {
        if (['map', 'cockpit'].includes(to.name)) {
          console.log(from,'fromfrom')
          !['map', 'cockpit'].includes(from.name) && sessionStorage.setItem('fromName', from.fullPath)
          this.active = to.name
        } else {
          this.active = 'other'
        }
      },
      immediate: true,
    }
  },
  mounted() {
    if (this.$store.state.systemConfig.iconFileUrl) {
      this.resetLink(this.$store.state.systemConfig.iconFileUrl)
    } else {
      this.resetLink("/file/get/a938d278e3dd4bd520240608003027"+this.setToken())
    }
    if (this.$store.state.systemConfig.systemName) {
      this.resetTitle(this.$store.state.systemConfig.systemName)
    } else {
      // this.resetTitle("地质灾害预警管理系统")
      this.resetTitle("监测预警管理系统")
    }
    this.showHeader = false
    this.$nextTick(() => {
      this.showHeader = true
    })
  },
  methods: {
    setToken() {
      let access_token = sessionStorage.getItem('access_token').replace('bearer ', '')
      return '?access_token=' + access_token
    },
    resetTitle(systemName) {
      const title = document.querySelector('title[rel~="title"]');
      if (title) {
        title.innerHTML = systemName;
      } else {
        const newTitle = document.createElement('title');
        newTitle.rel = 'title';
        newTitle.href = systemName;
        document.getElementsByTagName('head')[0].appendChild(newTitle);
      }
    },
    resetLink(iconFileUrl) {
      // 动态替换favicon
      const link = document.querySelector('link[rel~="icon"]');
      if (link) {
        link.href = this.$FileBaseURL + iconFileUrl;
      } else {
        const newLink = document.createElement('link');
        newLink.rel = 'icon';
        newLink.href = this.$FileBaseURL + iconFileUrl;
        document.getElementsByTagName('head')[0].appendChild(newLink);
      }
    },
    tagRoute(name) {
      this.active = name
      if (name == 'other') {
        this.$router.replace({ path: sessionStorage.getItem('fromName')!='null'?sessionStorage.getItem('fromName'):"home" })
      } else {
        this.$router.replace({ name })
      }
    },
    loginOut() {
      this.$router.push("/")
      this.$nextTick(() => {
        sessionStorage.setItem('access_token', '')
        this.$store.commit('setAccessToken', '')
        sessionStorage.setItem('menulist', JSON.stringify([]))
        window.location.reload();
      })
    },
    setTreeName(treeData, menulist) {
      for (let item of treeData) {
        item.name = item.path
        delete item.path
        menulist.push(JSON.parse(JSON.stringify(item)))
        delete menulist[menulist.length - 1].subMenus
        if (item.subMenus && Array.isArray(item.subMenus) && item.subMenus.length) {
          menulist[menulist.length - 1].children = []
          this.setTreeName(item.subMenus, menulist[menulist.length - 1].children)
        }
      }
    },
    handleCommand(key) {
      switch (key) {
        case 'loginOut':
          this.loginOut()
          break;
        case 'switchRole':
          break;
        case 'updatePsw':
          this.$store.commit('setConfigObj', { slotName: '修改密码', title: "修改密码", width: "400px" })
          this.$store.commit('setDlFormObj', { pageName: "修改密码" })
          this.$store.commit('setDlShow', true)
          break;
        default:
          // 设置菜单
          let userInfo = (JSON.parse(this.$store.state.userInfo).bindRoleInfos || []).find(item => item.id * 1 == key * 1) || {}
          let subMenus = userInfo.menuInfos
          let newUserInfo = this.$store.state.userInfo ? JSON.parse(this.$store.state.userInfo) : {}
          newUserInfo.roleName = userInfo.name
          newUserInfo.roleId = userInfo.id;
          newUserInfo.isAdmin = userInfo.isAdmin;
          this.$store.commit('setUserInfo', JSON.stringify(newUserInfo))
          if (!subMenus || !Array.isArray(subMenus) || !subMenus.length) {
            this.$message.error('该角色未配置菜单，请联系管理员')
            return
          }
          let routers = []
          this.setTreeName(subMenus, routers)
          setRouterByMenu(routers)
          sessionStorage.setItem('menulist', JSON.stringify(routers || []))
          this.$router.push({ name: 'home' })
          location.reload();
          // this.$message.success('角色切换成功')
          break;
      }
    },
    computed: {
      userInfo() {
        return this.$store.state?.userInfo?JSON.parse(this.$store.state.userInfo ):{}
      },
    }
  }
};
</script>
<style scoped lang="scss">
.clear .right {
  display: inline-block;
}

.popovers {
  transform: translateY(10px);
  cursor: pointer;
}

.t-l {
  transform: translate3d((-100px, -20px, 0))
}

.logo {
  //width: 195.86px;
  height: 28.48px;
  //background-image: url('@/assets/uiBack/login-title-blue.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 0 20px 0 0;

  img {
    height: 30px;
    margin-right: 5px;
  }

}

.menu {
  font-family: Alibaba PuHuiTi;
  color: #0257de !important;
  line-height: normal;
  font-size: 14px;
  margin: 0 10px;
  cursor: pointer;

  img {
    width: 12px;
    height: 12px;
    margin-right: 6px;
    transform: translateY(2px);
  }
}

.active {
  padding: 3px 0;
  border-bottom: 3px solid #0257de !important;
  cursor: pointer;
  border-radius: 0 0 2px 2px;
}

.sub-title {
  height: 15px;
  line-height: normal;
  font-family: Alibaba PuHuiTi;
  color: #080808;
  font-size: 12px;
  white-space: nowrap;
}

.right-wrap {
  width: calc(100% - 208px);
  padding: 0 0 0 0;
}

.logo-title {
  font-family: 'PingFang SC ', 'PingFang SC', sans-serif;
  font-style: normal;

  span {
    display: block;
    line-height: 1;
  }

  :first-child {
    font-weight: 600;
    letter-spacing: 5px;
    color: #FFFFFF;
    margin-bottom: 5px;
  }

  :nth-child(2) {
    font-size: 12px;
    letter-spacing: 3.5px;
    color: #FFFFFF;
  }
}

.header-right {

  .el-avatar {
    margin-right: 10px;
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  span,
  .el-icon-caret-bottom {
    color: rgb(62, 62, 62);
  }

  .el-icon-caret-bottom {
    font-size: 16px;
    margin-left: 10px;
  }
}

.icon-shebeiguanli {
  font-size: 28px;
  margin-right: 6px;
}

.userInfo-span {
  color: #adabab;
  width: 100%;
  text-align: center;
  display: inline-block;
}

::v-deep .el-dropdown-menu__item {
  line-height: 1;
  font-size: 13px;
}

::v-deep .el-popover {
  padding: 2px 15px;

}
</style>